// @import './general';
// @import './custom';

// DONATION
.donation-container {
    margin-top: 1.5rem;
}
.container {
    @include media-breakpoint-up(lg) {
        max-width: 1242px;
    }
}

.page-screen-donation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 1rem 10rem 1rem;
    min-height: 100vh;
}

.page-screen-donation .title-resume {
    max-width: 65vw;
}

.page-screen-donation .title-compaign {
    margin-top: 7rem;
    margin-bottom: 2rem;
}

// DONATION-ACCORDION
.accordion {
    .card {
        border: none;
        // background-color: #0a2497;
        border-radius: 1rem !important;
        margin-bottom: 2rem;
        .card-header {
            background-color: $light;
            border: none;
            // background-color: #f0f0f0;
            // background-color: #ec0303;
            border-radius: 1rem !important;
            z-index: 0;
            .accordion-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .accordion-title {
                    // background-color: #ec0303;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    p {
                        margin-bottom: 0;
                        font-size: 0.92rem;
                        font-weight: 700;
                        margin-left: 0.875rem;
                        color: $dark;
                    }
                }
                .accordion-icon {
                    // background-color: #ec0303;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    .accordion-units {
                        background-color: rgba($dark, 0.1);
                        color: rgba($dark, 0.6);
                        font-size: 1rem;
                        font-weight: 700;
                        padding: 0.125rem 0.875rem;
                        margin-right: 0.75rem;
                        border-radius: 10rem;
                    }
                }
            }
        }
        .card-body {
            padding: 0.5rem 1rem;
        }
    }
}

.donation-accordion {
    max-width: 800px;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 8rem;

    .card .card-header {
        // display: block;
        // position: sticky;
        // z-index: 2;
        // top: -1px;

        .accordion-amount {
            opacity: 0;
            transition: all .7s ease-out;
        }

        &.is-pinned .accordion-amount {
            opacity: 1;
        }
    }

    .donation-list .donation-item .product-info .product-info-text {
        justify-content: center;
    }
}

// Fixed button for donation screen
.donation-btn {
    position: fixed;
    bottom: 0;
    z-index: 5;
    background: white;
    width: 100%;
    left: 0;
    box-shadow: 0 0 1rem rgba(80, 80, 80, 0.2);

    &.form-group {
        margin-bottom: 0;
    }
}

// DONATION-ACCORDION-LIST
.donation-list {
    list-style: none;
    // padding-left: 0;
    padding: 0 1rem;
    .donation-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0;
        align-items: center;
        width: 100%;
        border-bottom: 0.063rem solid $light;
        .product-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .product-info-image {
                height: 3rem;
                width: 3rem;
                margin-right: 1rem;
                img {
                    height: 3rem;
                    width: 3rem;
                    -o-object-fit: fill;
                    object-fit: fill;
                }
            }
            .product-info-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 1rem;
                .product-name {
                    font-size: 0.925rem;
                    font-weight: 700;
                    color: $black;
                    margin-bottom: 0;
                    line-height: 1.1;
                    padding: 0.125rem 0;
                }
                .product-description {
                    font-size: 0.75rem;
                    font-weight: normal;
                    color: $dark;
                    margin-bottom: 0;
                    line-height: 1.1;
                    padding: 0.125rem 0;
                }
                .product-units {
                    font-size: 0.75rem;
                    font-weight: 700;
                    color: $dark;
                    margin-bottom: 0;
                    line-height: 1.1;
                    padding: 0.125rem 0;
                }
            }
        }
        .product-price {
            display: flex;
            align-items: center;
            .product-price-subtotal {
                font-size: 1.125rem;
                font-weight: 800;
                margin-bottom: 0;
            }
            input.product-quantity {
                max-width: 60px;
                min-width: 40px;
                margin-left: 10px;
                padding-left: 0.5rem;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.521) !important;
                }
            }
        }
        .form-check {
            label {
                img {
                    margin-right: 0.25rem;
                    margin-left: 1.3rem;
                }
            }
            .small {
                color: rgba($dark, 0.5);
                padding-left: 1.875rem;
                font-size: 0.75rem;
                margin-bottom: 0;
                // display: block;
            }
        }

        &.donation-cursor {
            cursor: pointer;
        }

        .form-check-label-donation {
            display: flex;
            width: 100%;
            justify-content: space-between;
            cursor: pointer;
        }
    }
}

// TOTAL PRICE RESUME
.total-price-container {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    background-color: $white;
    width: 100%;
    box-shadow: 0 0 1rem rgba($dark, 0.2);
    .total-price-container-display {
        display: grid;
        grid-auto-rows: auto;
        align-items: center;
        grid-row-gap: 1rem;
        @include media-breakpoint-up(sm) {
            display: grid;
            grid-template-columns: auto 14rem;
            grid-column-gap: 2rem;
        }
    }
    .total-price-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        // width: 100%;
        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
        .total-price-title {
            font-size: 1rem;
            font-weight: normal;
            color: $dark;
            @include media-breakpoint-up(lg) {
                margin-right: 2rem;
            }
        }
        .total-price-import {
            font-size: 1.25rem;
            font-weight: 900;
            color: $black;
        }
    }
}
.btn-send-donation {
    display: block;
    width: 100%;
    margin: 0;
    max-width: 100%;
    @include media-breakpoint-up(sm) {
        max-width: 14rem;
        display: block;
        width: auto;
        margin-left: auto;
    }
}

// TOTAL PRICE RESUME (KA)
.total-price-donation {
    display: flex;
    flex-direction: column;
    align-items: center;

    .total-price-don-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4rem;
        width: 100%;
        @include media-breakpoint-up(sm) {
            font-size: 1.6rem;
            width: 85%;
        }
        @include media-breakpoint-up(sm) {
            width: 75%;
        }

        .total-price-title {
            font-weight: normal;
            color: $dark;
            font-weight: 600;
        }

        .total-price-import {
            font-weight: 900;
            color: $black;
            @include media-breakpoint-up(sm) {
                font-size: 2rem;
            }
        }
    }

    .btn-don-button {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: 85%;
        }
        @include media-breakpoint-up(sm) {
            width: 75%;
        }
    }
}

.info-donation {
    display: grid;
    grid-template-columns: 1.5rem auto;
    grid-gap: 0.75rem;
    background-color: rgba($primary, 0.2);
    padding: 1rem;
    border-radius: 0.875rem;
    p {
        font-size: 0.875rem;
        margin: 0;
    }
}

.card-header-subscription {
    background-color: #b9f3ff !important;
}

.bg-subscription {
    background-color: rgba($info, 0.15);
    padding-top: 3rem !important;
    padding-bottom: 1.25rem !important;
    border-radius: 0.875rem;
    margin-top: -2rem !important;
    z-index: -9999;
}

.custom-control-label::after,
.custom-control-label::before {
    display: none;
}

.subscription-period {
    font-size: 0.825rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media-breakpoint-up(sm) {
        justify-content: flex-start;
    }
}

.subscription-footer {
    display: grid;
    grid-template-columns: 1.5rem auto;
    grid-gap: 0.75rem;
    background-color: rgba($primary, 0);
    padding: 1rem 0 0 0;
    margin-top: 1rem;
    border-top: 0.063rem solid $light;
    p {
        font-size: 0.875rem;
        line-height: 1.3;
        margin: 0;
    }
}

// SWITCH TOGGLE
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-top: 0.5rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.2);
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $primary;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

// Modal Fisica y Juridica close button style
.btn-close-modal {
    top: 25px;
    right: 30px;
    position: absolute;
    cursor: pointer;
}

.telefono-input-modal input::-webkit-outer-spin-button,
.telefono-input-modal input::-webkit-inner-spin-button {
    display: none; /* <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.telefono-input-modal input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}