// FORMS

form {
    width: 100%;
    max-width: 48rem;
    .form-group {
        margin-bottom: 2rem;
    }
    label {
        font-size: 0.75rem;
        margin-bottom: 0;
    }
    .form-control {
        border-radius: 0;
        border: none;
        border-bottom: 0.125rem solid $light;
        height: 2.25rem;
        padding-left: 0;
        background-color: rgba($white, 0);
        &:focus,
        &:active {
            border: 0;
            box-shadow: none;
            border-bottom: 0.125rem solid $primary;
            background-color: rgba($white, 0);
        }
    }
}

.input-required {
    color: $danger;
}

.text-sm {
    font-size: 13px;
}

input::-webkit-input-placeholder {
    color: $light !important;
}
input::-moz-placeholder {
    color: $light !important;
}
input:-ms-input-placeholder {
    color: $light !important;
}
input::-ms-input-placeholder {
    color: $light !important;
}
input::placeholder {
    color: $light !important;
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.form-check {
    padding-left: inherit;
}

.check-message {
    background-color: rgba($primary, 0.035);
    font-size: 0.875rem;
    border-radius: 0.875rem;
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    margin-left: 0.75rem;
    margin-top: 0.5rem;
}

.form-check-input:focus {
    border-color: lighten($primary, 10%);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
}

.form-row-import {
    background-color: #ececec;
    border-radius: 1rem;
    padding: 1rem 1rem 0 1rem;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    .form-group {
        margin-bottom: 1rem;
    }
    .form-control {
        font-size: 2rem;
        padding-left: 2rem;
        color: rgba($dark, 0.7);
    }
    .currency {
        font-size: 2rem;
        color: rgba($dark, 0.5);
    }
}
.check-radio {
    margin-left: -22px;
}

.form-row-label {
    label {
        font-weight: 600;
        font-size: .8rem;
    }
}

.form-row-donation {
    background-color: #ececec;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    margin-bottom: 1.7rem;

    label {
        display: none;
    }

    .form-group {
        margin-bottom: 0;
        margin: .3rem 0;
    }

    .form-donation {
        color: rgba($dark, 0.7);
        font-size: 1.6rem;
        height: 100%;
    }
}

.check-suscription {
    margin: .6rem 0 .6rem 3.5rem;
    padding: .4rem;
    background-color: rgba($primary, 0.15);
    color: black;
    border-radius: 3px;

    .form-check-suscription {
        margin-right: .2rem;
    }
}