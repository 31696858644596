// Responsive typography
html {
    font-size: 0.85rem;
    @include media-breakpoint-up(sm) {
        font-size: 0.9rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 0.95rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }
    overflow-x: hidden;
    min-height: 100%;
}
