html,
body {
    background-color: #f9fafa;
}
*,
body {
    color: $black;
    font-family: "Avenir", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black;
    font-family: "Avenir", sans-serif;
    font-weight: 700;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.375rem;
}

h3 {
    font-size: 1.875rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1.125rem;
}

p {
    color: $black;
    font-size: 1rem;
    line-height: 1.6;
}
input[type="text"] {
    color: $black;
    font-family: "Avenir", sans-serif;
}

/* --------------------------------------
General Styles
-------------------------------------- */
.page-screen-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-down(sm) {
        // padding: 2rem 1rem 4rem 1rem;
        min-height: 100vh;
    }
}

.page-logo {
    width: 125px;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: darken;
}

@media (min-width: 425px) {
    .page-logo {
        width: 140px;
    }
}

@media (min-width: 771px) {
    .page-logo {
        width: 26%;
    }

    .modal-header .page-logo {
        width: 45%;
    }
}

@media (min-width: 991px) {
    .page-logo {
        width: 18%;
    }
}

.page-title {
    margin-bottom: 3rem;
}

.download-app {
    margin-top: 1rem;
    img {
        margin-right: 1rem;
        height: 2rem;
    }
}

.small {
    font-size: 85%;
    padding-left: 3px;
    @include media-breakpoint-down(sm) {
        font-size: 100%;
    }
}

a {
    -webkit-transition: all 0.3s ease;
    color: $primary;
    transition: all 0.3s ease;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        color: darken($primary, 5%);
    }

    a:hover,
    a:focus,
    a:visited {
        outline: none !important;
        text-decoration: none !important;
    }

    input {
        outline: none !important;
    }

    button {
        outline: none !important;
    }
}

// FORMS
form {
    width: 100%;
    max-width: 48rem;
    .form-group {
        margin-bottom: 2rem;
    }
    label {
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-left: 5px;
    }
    .form-control {
        border-radius: 0;
        border: none;
        border-bottom: 0.125rem solid $light;
        height: 2.25rem;
        padding-left: 0;
        background-color: rgba($white, 0);
        &:focus,
        &:active {
            border: 0;
            box-shadow: none;
            border-bottom: 0.125rem solid $primary;
            background-color: rgba($white, 0);
        }
    }
    .danger {
        border-bottom: 0.125rem solid $danger !important;
    }
    .danger-chk {
        border: 0.125rem solid $danger !important;
    }
    .input-required {
        color: $danger;
    }
}

input::-webkit-input-placeholder {
    color: $light !important;
}

input::-moz-placeholder {
    color: $light !important;
}

input:-ms-input-placeholder {
    color: $light !important;
}

input::-ms-input-placeholder {
    color: $light !important;
}

input::placeholder {
    color: $light !important;
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}
input.form-check-input-donation[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  padding: 10px;
  cursor: pointer;
}
.text-suscription {
    opacity: 0.5;
    margin-left: 2rem;
}

.form-check {
    padding-left: inherit;
    margin-left: -2.2rem;
}
.form-check-input {
    position: relative;
    margin: 2px 5px 0 15px !important;
}
// .form-check-input-modal {
//     margin: 3px 0px 0 35px !important;
// }
.signup-check-input {
    margin: 3px 5px 0 15px !important;
    @include media-breakpoint-down(sm) {
        width: 26px;
    }
}
.form-check-suscripcion {
    margin: 2px 5px 0 5px !important;
}
.check-input-modal {
    width: 1rem;
    margin-top: 0.3rem;
    @include media-breakpoint-down(sm) {
        width: 2.3rem;
        height: 1.2rem;
    }
}

.check-message {
    background-color: rgba($primary, 0.035);
    font-size: 0.875rem;
    border-radius: 0.875rem;
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    margin-left: 0.75rem;
    margin-top: 0.5rem;
}

.form-check-input:focus {
    padding-left: 3px;
    border-color: lighten($primary, 10%);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
}
.form-check {
    display: flex;
    padding-left: 0rem;
    align-items: center;
}
.form-check-modal {
    display: grid;
    margin-left: 0px;
    grid-template-columns: .1fr 2fr;
    align-items: flex-start;
}

.form-row-import {
    background-color: #ececec;
    border-radius: 1rem;
    padding: 1rem 1rem 0 1rem;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    .form-group {
        margin-bottom: 1rem;
    }
    .form-control {
        font-size: 2rem;
        padding-left: 2rem;
        color: rgba($dark, 0.7);
    }
    .currency {
        font-size: 2rem;
        color: rgba($dark, 0.5);
    }
}

.btn-primary {
    background-image: linear-gradient(233deg, #39a1f7 0%, #8c51f2 100%, #7831f4 100%);
    min-width: 18rem;
}
.btn-primary-outline {
    border: 0 solid rgba($primary, 1);
    background-color: rgba($white, 0);
    color: $primary;
}
.btn-success {
    background-color: $success;
}

.download-app {
    margin-top: 1rem;
    margin-left: 1rem;

    img {
        height: 2rem;
    }
}

a {
    -webkit-transition: all 0.3s ease;
    color: $primary;
    transition: all 0.3s ease;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        color: darken($primary, 5%);
    }

    a:hover,
    a:focus,
    a:visited {
        outline: none !important;
        text-decoration: none !important;
    }

    input {
        outline: none !important;
    }

    button {
        outline: none !important;
    }
}

a {
    -webkit-transition: all 0.3s ease;
    color: $primary;
    transition: all 0.3s ease;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        color: darken($primary, 5%);
    }

    a:hover,
    a:focus,
    a:visited {
        outline: none !important;
        text-decoration: none !important;
    }

    input {
        outline: none !important;
    }

    button {
        outline: none !important;
    }
}
// Modal
.modal {
    padding-right: 1rem;
    padding-left: 1rem;
}
.modal-facturacion {
    position: fixed;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal-content {
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-radius: 1rem;
}
.modal-header,
.modal-footer {
    border: none;
}

.danger-chk {
    border: 0.125rem solid $danger !important;
}

//footer
.styleFooter {
    position: static;
    bottom: 0;
    width: 100%;
    @include media-breakpoint-up(sm) {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: left !important;
    }
}

.redes {
    margin-left: -2.5rem;
    @include media-breakpoint-down(sm) {
        padding-top: 1rem !important;
        margin-left: -0.5rem;
    }
}
.fontBold {
    font-weight: 600;
}
.full-width {
    background-color: #a8adff3b;
    position: relative;
    width: 100vw;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin-top: 20px;
    margin-left: -50vw;
    margin-right: -50vw;
}

@media (min-height: 1000px) {
    .full-width {
        position: absolute;
    }
}

.btn-modal {
    width: 24rem;
}
.btn-width {
    width: max-content;
}

.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.wrapper .option {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(139, 139, 139, 0.3);
    color: #adadad;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    padding: 0 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s;
}
.radio-suscripcion {
    display: none;
}
#customRadioInline1:checked:checked ~ .customRadioInline1,
#customRadioInline2:checked:checked ~ .customRadioInline2,
#customRadioInline3:checked:checked ~ .customRadioInline3 {
    border-color: #12bbd4;
    background: #12bbd4;
}
.wrapper .option span {
    font-size: 1rem;
    color: #808080;
}
#customRadioInline1:checked:checked ~ .customRadioInline1 span,
#customRadioInline2:checked:checked ~ .customRadioInline2 span,
#customRadioInline3:checked:checked ~ .customRadioInline3 span {
    color: #fff;
}
.display-form {
    @include media-breakpoint-up(sm) {
        display: flex;
        .form-control {
            width: 96%;
        }
    }
}


/* Language Selector Styles */
.translations {
    position: absolute;
    right: 0px;
    margin-right: 4rem;
    z-index: 5;

    .lang {
        position: relative;
        box-shadow: 0 0.938rem 1.875rem 0 rgb(46 46 46 / 10%);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.493);

        &-selected,
        &-each {
            display: flex;
            align-items: center;
            padding: .4rem 1rem;
            min-width: 130px;
            cursor: pointer;

            img {
                width: 30px;
                height: 30px;
                margin-right: .3rem;
                border-radius: 100vh;
            }

            span {
                font-size: 14px;
            }
        }

        &-selected {
            background: #fdfdfd;
            border-radius: 10px;
        }

        &-list {
            position: absolute;
            top: 110%;
            box-shadow: 0 0.938rem 1.875rem 0 rgb(46 46 46 / 10%);
            border-radius: 10px;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all .2s ease-in-out;
            background: #fdfdfd;

            .lang-each {
                border-bottom: 1px solid #00000015;
                padding: .5rem 1rem;
                min-width: 130px;

                &:hover {
                    background-color: rgba(230, 230, 230, 0.253);
                }
            }
        }
    }
}

@media (max-width: 1025px) {
    .translations {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-right: 1rem;
    }
}

/* Reset password main page */
.container {

    &-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        min-width: 310px;
        max-width: 370px;

        &__div {
            position: relative;
            padding: 1.5rem 1rem;
            border-radius: 10px;
            box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.42);
        }
    }

    &-header {
        padding: .5rem 0 2rem 0;
        text-align: center;

        .page-logo {
            width: 250px;
        }
    }

    &-body {
        form {
            .form-control {
                border: 0.125rem solid #c0c0c0;
                padding: 0 1rem;

                &:focus {
                    border: 0.125rem solid $primary;
                }
            }

            .form-group {
                margin-bottom: 1.2rem;
            }

            .danger {
                border: 0.125rem solid $danger !important;
            }

            button.btn {
                border-radius: 6px;
                font-weight: 500;
                box-shadow: none;

                &.btn-info {
                    background-color: #0cc6cb;
                }

                &:hover {
                    color: white;
                }

                &:disabled {
                    color: white;
                    opacity: .5;
                }
            }
        }

        .container-success {
            padding: 1rem 0 1rem 2rem;
            border-left: 6px solid #6F943E;
            background: #C6DCC4;
        }
    }
}