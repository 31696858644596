// MODAL
.modal {
    padding-right: 1rem;
    padding-left: 1rem !important;
}
.modal-content {
    padding: 2rem 1rem;
    border-radius: 0.625rem;
    // box-shadow: 0 0 0.625rem rgba($black, 0.15);
}
.modal-header,
.modal-footer {
    border: none;
}
.modal-footer {
    display: flex;
    flex-direction: column;
}
.modal-header {
    justify-content: center;
}
.loading-modal .modal-content {
    padding: .5rem;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}

.loading-modal .loading-error {
    width: 100%;
}

.loading-modal .loading-error button {
    border: none;
    background: transparent;
    float: right;
    margin: .3rem 0;
}

.loading-modal .loading-error span {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: inline-block;
    font-size: 20px;
}

.modal-dialog {
    width: 99%;
}