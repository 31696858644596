// SWIPER SLIDER

.swiper {
    width: 100%;
    height: auto;
    margin-left: 8px;

    .swiper-wrapper {
        display: flex;
        height: auto;
        justify-content: flex-start;
        // align-items: center;
        flex-direction: row;

        .swiper-slide {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 100%;
                height: 100%;
                @include media-breakpoint-up(sm) {
                    width: 12rem;
                    margin-bottom: 1rem;
                }
                @include media-breakpoint-up(md) {
                    // ?
                    width: 15rem;
                    margin-bottom: 1rem;
                }
            }
            .swiper-slide-title {
                font-weight: 900;
            }
            .swiper-slide-description {
            }
        }

        .swiper-congrat {
            box-shadow: 0 0 0.625rem rbga($black, 0.22);
            border-radius: 0.625rem;
            padding: 2rem 1rem 1rem 1rem;
            min-width: 90px;
            .swiper-slide-img {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 30rem;
                height: 3.25rem;
                width: 3.25rem;
                background-color: rgba($white, 0.27);
                margin-bottom: 1rem;
                img {
                    width: 2rem;
                    height: 2rem;
                    margin: 0;
                }
            }
            .swiper-slide-name {
                color: $white;
                font-size: 1rem;
                line-height: 1.15;
                margin-bottom: 1rem;
                sub {
                    color: $white;
                }
            }
            .swiper-slide-quantity {
                color: $white;
                font-weight: 900;
                font-size: 1.5rem;
                margin-bottom: -0.5rem;
            }
            .swiper-slide-unit {
                color: $white;
                font-weight: 900;
                font-size: 1rem;
            }
        }
        .bg-1 {
            background-color: #10c177;
        }
        .bg-2 {
            background-color: #fdbf00;
        }
        .bg-3 {
            background-color: #ff6c6c;
        }
        .bg-4 {
            background-color: #76e2f8;
        }
        .bg-5 {
            background-color: #44d7b6;
        }
        .bg-6 {
            background-color: #ffd400;
        }
        .bg-7 {
            background-color: #2ad414;
        }
        .bg-8 {
            background-color: #7e5df0;
        }
        .bg-9 {
            background-color: #f55858;
        }
    }
    .swiper-pagination {
        position: relative;
        margin-top: 1rem;
    }
    .swiper-pagination-bullet {
        background: $primary;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 2rem;
    }
}
