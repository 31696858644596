
// BS overrides
$primary:       #7E5DF0;
$secondary:     #14BFE1;
$success:       #10C177;
$info:          #76E2F8;
$warning:       #FDBF00;
$danger:        #FF6C6C;
$dark:          #505050;
$light:         #F0F0F0;
$black:			#2E2E2E;

$theme-colors: ();
$theme-colors: map-merge((
"primary":    $primary,
"secondary":  $secondary,
"success":    $success,
"info":       $info,
"warning":    $warning,
"danger":     $danger,
"light":      $light,
"dark":       $dark
), $theme-colors);


$body-color:                $dark;

$link-color:                $dark;
$link-decoration:           none;
$link-hover-color:          $black;
$link-hover-decoration:     none;


$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$headings-font-family:   -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$headings-font-weight:        200;
$headings-line-height:        1.05;

$h1-font-size:                $font-size-base * 3;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base;

$nav-link-padding-y:1.35rem;

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1400px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
// sm: 540px,
// md: 720px,
// lg: 960px,
sm: 758px,
md: 962px,
lg: 1242px,
xl: 1242px
);
