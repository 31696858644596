// BUTTONS
.btn {
    border-radius: 100px;
    font-size: 0.875rem;
    font-weight: 900;
    letter-spacing: 0.05rem;
    border: 0;
    padding: 0.75rem 2rem;
    transition: 0.3s;
    text-transform: uppercase;
    box-shadow: 0 0.938rem 1.875rem 0 rgba($black, 0.15);
    color: $white;
    margin: 0.5rem;
    width: auto;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25rem);
        background-color: $white;
        background-image: none;
        color: $primary;
    }
}

.btn-long-text {
    cursor: pointer;
    @include media-breakpoint-down(md) {
        font-size: 0.8rem;
        letter-spacing: 0.01rem;
        padding: 0.75rem 1rem;
    }
}

.btn-primary {
    background-image: linear-gradient(233deg, #39a1f7 0%, #8c51f2 100%, #7831f4 100%);
    min-width: 18rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-certificado {
    background-color: khaki;
    width: max-content;
}
.btn-secondary {
    background-color: $secondary;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-primary-outline {
    border: 0 solid rgba($primary, 1);
    background-color: rgba($white, 0);
    color: $primary;
}

.btn-link,
a:not([href]):not([tabindex]) {
    cursor: pointer;
    font-weight: 900;
    text-decoration: underline;
    color: $primary;
    &:hover {
        color: darken($primary, 5%);
    }
}
.btn-success {
    background-color: $success;
}
.btn-sso {
    cursor: pointer;
    border-radius: 0.625rem;
    text-align: center;
    box-shadow: 0 0 1.25rem rgba($black, 0.12);
    display: grid;
    grid-template-columns: 1.5rem auto;
    align-items: center;
    padding-left: 1rem;
    text-transform: none;
    width: 100%;
    max-width: 18rem;
    .btn-label {
        margin-right: 1rem;
        img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.btn-apple {
    background-color: rgba(0, 0, 0, 0.92) !important;
    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}
.btn-fb {
    background-color: #166fe5 !important;
    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}
.btn-google {
    background-color: $white !important;
    color: $black;
    &:hover,
    &:active,
    &:focus {
        color: $black;
    }
}

.btn-logout {
    position: absolute;
    width: auto;
    right: 1rem;
    top: 1rem;
    background-color: rgba($primary, 0.1);
    box-shadow: none;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    @include media-breakpoint-down(sm) {
        padding: 1rem;
    }

    .btn-logout-icon {
        img {
            opacity: 0.7;
        }
    }

    .btn-logout-text {
        margin-top: 0.25rem;
        text-transform: none;
        font-weight: 700;
        color: rgba($primary, 0.7);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &:hover,
    &:active,
    &:focus {
        .btn-logout-icon {
            img {
                opacity: 1;
            }
        }
        .btn-logout-text {
            color: rgba($primary, 1);
        }
    }
}

.btn-pdf {
    background-color: rgba($success, 0);
    box-shadow: none;
    padding: 0.5rem 1.5rem;

    .btn-pdf-text {
        text-transform: none;
        font-weight: 700;
        color: rgba($success, 0.7);
    }

    .spinner-loading {
        margin-top: 0px;
    }
}

.download-app {
    margin-top: 1rem;
    img {
        height: 2rem;
    }
}

a {
    -webkit-transition: all 0.3s ease;
    color: $primary;
    transition: all 0.3s ease;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        color: darken($primary, 5%);
    }

    a:hover,
    a:focus,
    a:visited {
        outline: none !important;
        text-decoration: none !important;
    }

    input {
        outline: none !important;
    }

    button {
        outline: none !important;
    }
}

.spinner-loading {
    color: #8c51f2;
    margin: 20px 0 0 10px;
}
